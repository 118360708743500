.switch {
    display: inline-block;
    width: 60px;
    height: 34px;
    position: relative;
}

.toggle-thumb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #475569;
    border-radius: 40px;
    cursor: pointer;
}

.toggle-thumb:before {
    content: "";
    height: 26px;
    width: 26px;
    position: absolute;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: #E2E8F0;
    transition: .4s all ease;
}

.checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkbox:checked + .toggle-thumb:before {
    transform: translateX(26px);
}
