img {
    width: 100px;
}

.home-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 130px;
}

.btn > img {
    vertical-align: middle;
}
